
$black-1: #111;
$black-2: #222;

$blue-1: #203969;
$blue-2: #265999;
$blue-3: #4488BF;

$gold-1: #5b4a18;
$gold-2: #CA9703;
$gold-3: #D5AD36;
$gold-4: #D1C6B1;

$gray-1: #444;
$gray-2: #66676F;
$gray-3: #c6c7cf;
$gray-4: #eee;


$gray-blue: #49677B;

$red-2: #934932;