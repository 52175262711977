@import "./styles/main.scss";

.App {
    background-color: $gray-4;
    min-height: 100vh;
}

.About {
    @include f-grid();

    position: relative;
    margin-bottom: 40px;

    @include screen-start-at(m) {
        margin-bottom: 65px;
    }

    .card {
        left: 50%;
        width: 0;

        @include screen-start-at(m) {
            position: absolute;
            z-index: 3;
            width: 20vw;
            left: initial;
            
            &--a {
                top: 10%;
                left: 2vw;
                transform: rotate(-30deg);
            }

            &--b {
                width: 15vw;
                top: 40%;
                right: 3vw;
                transform: rotate(15deg);
            }
        }

        @include screen-start-at(l) {
            width: 15vw;
        }

    
    }

    .container {
        @include f-item(12);
        @include f-grid();

        z-index: 5;
        padding: 20px;
        background-color: $gray-3;

        @include screen-start-at(m) {
            @include f-item(10, 1, 1);

            padding: 40px;
            margin-bottom: 80px;
        }

        .content {
            @include f-item(10, 1, 1);

            margin-bottom: 35px;

            @include screen-start-at(m) {
                @include f-item(6, 3, 3);
            }

            @include screen-start-at(l) {
                @include f-item(4, 1, 1);
                margin-bottom: 50px;
            }

            h2 {
                margin-bottom: 12px;
                color: $gray-1;
                font-size: 1.6em;
                font-style: italic;
            }

            p {
                color: $gray-2;
                font-size: 1.35em;
            }
        }

    }
}

.Contact {
    @include f-grid();

    min-height: 40vh;

    @include screen-start-at (m) {
        // margin-bottom: 60px;
    }

    .link-button {
        @include f-item(8, 2, 2);

        padding: 15px;
        margin-bottom: 25px;

        background-color: $blue-3;
        
        color: $gray-4;
        text-align: center;
        font-size: 1.3em;
        text-decoration: none;

        transition: 0.3s;

        &:hover {
            background-color: $blue-1;
        }

        @include screen-start-at (m) {
            @include f-item(4)
        }

        @include screen-start-at(l) {
            @include f-item(3);

            &:nth-child(3n-1) {
                @include f-item(3, 1.5);
            }
        } 

    }

    .title {
        @include f-item(8, 2, 2);

        margin-bottom: 35px;
        color: $gold-2;
        text-align: center;
        font-size: 3em;

        @include screen-start-at(m) {
            margin-bottom: 55px;
        }
    }
}

.Header {
    @include f-grid();

    margin-bottom: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
    
    .logo {
        @include f-item(4);
    }
}

.Hero {
    @include f-grid();

    margin-bottom: 40px;

    .container {
        @include f-item(10, 1);

        display: flex;
        flex-direction: column;

        @include screen-start-at(m) {
            flex-direction: row;
            margin-bottom: 60px;
        }

        .primary {
            position: relative;
            overflow: hidden;
            background-color: $gray-3;
            

            padding: 20px;
            margin-bottom: 15px;

            display: flex;
            flex-direction: column;

            @include screen-start-at(m) {
                margin-right: 25px;
                padding: 40px;

                flex: 1;
                justify-content: space-evenly;
            }

            .bg-img {
                // blurry stadium with fans
                // in focus player (from a card? 52 mickie mantel)

                @include blurry-bg(50%, 0%);
                background-image: url("img/stadium.jpg");
                background-position: center;

                filter: blur(5px) grayscale(40%) sepia(10%);
            }

            .bg-player {
                z-index: 4;
                position: absolute;
                bottom: -5%;
                right: -5%;

                width: 55%;
                transform: rotate(-10deg)
            }

            .button {
                z-index: 5;
                background-color: $gold-3;

                padding: 15px;
                text-align: center;
                text-decoration: none;

                span {
                    z-index: 5;
                    font-size: 1.25em;
                    color: white
                }

                transition: 0.4s;
                
                &:hover {
                    background-color: $gold-2;
                }

                @include screen-start-at(m) {
                    width: fit-content;
                }

            }

            h2 {
                z-index: 5;
                font-size: 2em;
                margin-bottom: 20px;
                
                color: $blue-1;
                text-shadow: 2px 2px $gold-4;

                font-style: italic;

                @include screen-start-at(m) {
                    font-size: 2.5em;
                }

            }

            ul {
                z-index: 5;
                margin-bottom: 20px;
                padding: 20px 40px;
                width: fit-content;
            }

            li {
                margin-left: 20px;
                font-size: 1.5em;
                color: $gray-4;
                text-shadow: 2px 2px $gray-1;
            }
            
        }

        .secondary-container {

            // flex: 1;

            display: flex;
            flex-direction: column;
            
            @include screen-start-at(m) {
                width: 33%;
            }

            .secondary {
                overflow: hidden;

                padding: 8vw 30px;
                margin-bottom: 15px;

                position: relative;

                flex: 1;

                display: flex;
                align-items: center;
                justify-content: center;

                h3 {
                    z-index: 5;

                    font-size: 1.5em;
                    color: $gray-4;
                    text-shadow: 2px 4px $blue-1;
                }

                &--a {
                    background-color: $gray-3;

                    .bg-img {
                        @include blurry-bg(15%);
                        background-image: url("img/comics.jpg");
                    }
                }

                &--b {
                    background-color: $gray-3;

                    .bg-img {
                        @include blurry-bg(15%);
                        background-image: url("img/cards.jpg");
                    }
                }
            }
        }
    }
}

.Footer {
    @include f-grid();
    min-height: 150px;
    background-color: $blue-2;
}